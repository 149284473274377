export const BG_COVER = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/1.%20FOTO%20COVER%20PORTRAIT_PMaVAd45L.jpg?updatedAt=1701145607894`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/2.%20FOTO%20COVER%20DALAM%20PORTRAIT_1O4ocBtxQ.jpg?updatedAt=1701145608624`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/COVER%20MARTIN%20BEBY_KTBJIc8Mf.jpg?updatedAt=1701153218368`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/6.%20FOTO%20DESKTOP%20VIEW%20LANDSCAPE_gHDehDmvPe.jpg?updatedAt=1701145608446`;

export const IMG_AYAT = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/5.%20FOTO%20FOOTER%20PORTRAIT_kJvZODJBz-.jpg?updatedAt=1701145608510`;
export const IMG_LOGO = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/Logo_8whLfMXYP.png?updatedAt=1701151769662`;

export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/3.%20FOTO%20GROOM_oxnz7a3WP.jpg?updatedAt=1701145607892`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/3.%20FOTO%20BRIDE_KvMuogu2t.jpg?updatedAt=1701145607938`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/4.%20FOTO%20COVER%20GALLERY%201_erA0ngFg3B.jpg?updatedAt=1701145608316`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/4.%20FOTO%20COVER%20GALLERY%202_TP7rvnqjP.jpg?updatedAt=1701145608449`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/OLE00357_b1jGc4XzI.jpg?updatedAt=1701151288848`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
